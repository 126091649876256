// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../shared/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TO9bCLeUHU9qtRPqxah6 {\n  margin: 0.5rem;\n  width: 100%;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./app/javascript/app/components/Loading/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,mBAAA;AACF","sourcesContent":[".loading {\n  margin: .5rem;\n  width: 100%;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "TO9bCLeUHU9qtRPqxah6"
};
export default ___CSS_LOADER_EXPORT___;
