import './jquery.fileupload';
import './jquery.iframe-transport';
import './jquery.ui.touch-punch';
import './jquery.ui.widget';
import './jquery.menu-aim';
import './jquery.validate.min';
import './lightbox';
import './lodash.min';
// import './modernizr';
import './remodal';
import './slick';
