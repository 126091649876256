function execMemberPurchase(response) {
    document.getElementById("token").disabled = false;
    if (response.resultCode !== "000" && response.resultCode !== 0) {
        var lang = "ja";
        window.alert('Error: ' + getResultCodeDetail(response.resultCode, lang));
    } else {
        //カード番号など不要な（保持・通過すべきでない）データを削除する
        document.getElementById('cardno').value = "";
        document.getElementById('securitycode').value = "";
        document.getElementById('expire_year').value = "";
        document.getElementById('expire_month').value = "";
        document.getElementById('holderfirstname').value = "";
        document.getElementById('holderlastname').value = "";
        //$('form').submit();
        $.ajax({
            url: window.gon.paycast,
            type: 'PATCH',
            data: {univa_paycast_info: response.tokenObject},
            success: function (data) {
                if (data.ok) {
                    $('form')
                        .submit();
                }
            }
        })
    }
}

function getMemberToken() {
    document.getElementById("token").disabled = true;
    var cardno, securitycode, expire, holderfirstname, holderlastname, memberid, membercode,
        email, phonenumber;
    cardno = document.getElementById('cardno').value;
    securitycode = document.getElementById('securitycode').value;
    expire = document.getElementById('expire_month').value + '' +
        document.getElementById('expire_year').value;
    holderfirstname = document.getElementById('holderfirstname').value;
    holderlastname = document.getElementById('holderlastname').value;
    memberid = document.getElementById('memberid').value;
    membercode = document.getElementById('membercode').value;
    email = document.getElementById('email').value;
    phonenumber = document.getElementById('phone_number').value;
    Multipayment.init(window.gon.univa_paycast_shop_id); //当社発行の店舗ID
    Multipayment.getMember({　//決済フォームより取得した情報
            cardno: cardno, //カード番号
            securitycode: securitycode, //セキュリティコード
            expire: expire, //カード有効期限
            holderfirstname: holderfirstname, //カードホルダー名
            holderlastname: holderlastname, //カードホルダー姓
            memberid: memberid, //会員番号
            membercode: membercode, //会員コード
            email: email, //メール
            phonenumber: phonenumber//電話番号
        },
        execMemberPurchase);

    return false;
}
